var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
// @ts-ignore
import React from "react";
import GoogleAutocomplete from "./GoogleAutocomplete";
var LocalitySearch = /** @class */ (function (_super) {
    __extends(LocalitySearch, _super);
    function LocalitySearch() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.render = function () {
            var loadingElement = _this.getLoadingElement();
            var results = _this.state.loading ? loadingElement : _this.renderResults();
            return (React.createElement("div", { className: "container container---form" },
                React.createElement("div", { className: "locality-search" },
                    React.createElement("header", { className: "locality-search__header" },
                        React.createElement("form", { className: "form form--search" },
                            React.createElement("input", { type: "text", role: "searchbox", onChange: _this.onInputChange, value: _this.state.inputValue, className: "form__control search", placeholder: "Search" }),
                            React.createElement("button", { type: "button", className: "form__search" },
                                React.createElement("svg", null,
                                    React.createElement("use", { xlinkHref: "#icon--search" }))))),
                    React.createElement("section", { className: "locality-search__results" }, results))));
        };
        return _this;
    }
    return LocalitySearch;
}(GoogleAutocomplete));
export default LocalitySearch;
